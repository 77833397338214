<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Fun & Games Budget</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon class="icon" [source]="'assets/icons/wallet.svg'" />
      <span>Your Budget</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="me$ as me; error as meError; suspenseTpl: spinner">
    <div *ngIf="meError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadMe()"
      >
        Reload Your Budget
      </p-button-pure>
    </div>

    <div *ngIf="!meError && me" class="budget-card">
      <div class="date">
        <p-text [align]="'center'" [size]="'x-small'">
          {{ now | date: 'MMMM' }}
          <br aria-hidden="true" />
          {{ now | date: 'dd' }}
        </p-text>
      </div>

      <div class="amount">
        <p-text [size]="'x-small'">Available Budget</p-text>
        <p-text [color]="'notification-info'" [size]="'large'" [weight]="'semi-bold'">
          {{ me.teamBudget | currency: 'EUR' }}
        </p-text>
      </div>
    </div>
  </ng-container>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon class="icon" [source]="'assets/icons/groups.svg'" />
      <span>Your Groups</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="groups$ as groups; error as groupsError; suspenseTpl: spinner">
    <div *ngIf="groupsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadGroups()"
      >
        Reload Your Groups
      </p-button-pure>
    </div>

    <div *ngIf="!groupsError && groups" class="group-card-container">
      <div *ngFor="let group of groups | orderBy: 'name'; trackBy: trackGroup" class="group-card">
        <div class="header">
          <p-link-pure
            [alignLabel]="'start'"
            [iconSource]="'assets/icons/arrow-right.svg'"
            [size]="'x-small'"
            [stretch]="true"
          >
            <a [routerLink]="['/groups', group.id]">
              <p-text [size]="'x-small'" [weight]="'semi-bold'">{{ group.name }}</p-text>
            </a>
          </p-link-pure>
        </div>

        <p-divider aria-hidden="true" />

        <div class="content">
          <p-text [size]="'xx-small'">{{ group.users.length }} members</p-text>
          <div
            #groupMembers="opaGroupMembers"
            class="members"
            opaGroupMembers
            [gapWidth]="8"
            [memberWidth]="32"
          >
            <ng-container
              *ngrxLet="
                group.users.length <= groupMembers.maxIcons
                  ? groupMembers.maxIcons
                  : groupMembers.maxIcons - 1 as maxUsers
              "
            >
              <opa-user-icon
                *ngFor="let user of group.users | slice: 0 : maxUsers; trackBy: trackUser"
                [name]="user.firstName + ' ' + user.lastName"
              />
              <opa-user-icon
                *ngIf="group.users.length > maxUsers"
                [isCounter]="true"
                [name]="'+' + (group.users.length - maxUsers)"
              />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon class="icon" [source]="'assets/icons/credit-card.svg'" />
      <span>Transaction Log</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="transactionLogData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadTransactionLogData()"
      >
        Reload Transaction Log
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <p-select-wrapper class="year-select" [label]="'Year'">
        <select [formControl]="yearControl">
          <option *ngFor="let year of data.activeYears" [value]="year">
            {{ year }}
          </option>
        </select>
      </p-select-wrapper>

      <p-text *ngIf="data.transactionLogs.length === 0" class="transaction-log-empty">
        No transactions.
      </p-text>

      <ng-container *ngFor="let yearLogs of data.transactionLogs; trackBy: trackYearLog">
        <div
          *ngFor="let monthLogs of yearLogs.yearTransactionLog; trackBy: trackMonthLog"
          class="transaction-log"
        >
          <p-text class="month">{{ monthLogs.monthName | titlecase }}</p-text>

          <ng-container
            *ngFor="let log of monthLogs.monthTransactionLog; trackBy: trackLog; first as first"
          >
            <div
              class="transaction"
              [ngClass]="{
                first: first,
                info: log.type === TransactionLogType.AVAILABLE,
                inflow:
                  log.type !== TransactionLogType.REMAINING &&
                  log.type !== TransactionLogType.AVAILABLE &&
                  log.budgetChange >= 0,
                outflow:
                  log.type !== TransactionLogType.REMAINING &&
                  log.type !== TransactionLogType.AVAILABLE &&
                  log.budgetChange < 0
              }"
            >
              <div class="date">
                <p-text [size]="'xx-small'">{{ log.dateCreated | date: 'dd' }}</p-text>
              </div>

              <div class="message">
                <p-text *ngIf="!log.event" [size]="'x-small'">
                  {{ transactionLogTypeTranslation[log.type] | transloco | titlecase }}
                </p-text>

                <ng-container *ngIf="log.event">
                  <div class="message-line">
                    <p-text [size]="'x-small'">{{ log.event.vendorName }}</p-text>
                  </div>

                  <div class="message-line">
                    <p-icon [source]="'assets/icons/groups3.svg'" />
                    <p-text [size]="'xx-small'">
                      <ng-container
                        *ngFor="
                          let user of log.event.participants;
                          trackBy: trackUser;
                          last as last
                        "
                      >
                        <span>{{ user.firstName }} {{ user.lastName }}</span>
                        <span *ngIf="!last">, </span>
                      </ng-container>
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-icon [source]="'assets/icons/invoice.svg'" />
                    <p-text [size]="'xx-small'">
                      Total event cost:
                      {{ log.event.totalEventCost | currency: 'EUR' }}
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-icon [source]="'assets/icons/calendar.svg'" />
                    <p-text [size]="'xx-small'">{{ log.dateCreated | date: 'dd.MM.yyyy.' }}</p-text>
                  </div>

                  <div class="message-line">
                    <p-link-pure [icon]="'none'" [size]="'x-small'" [underline]="true">
                      <a [routerLink]="['/events', log.event.id]">See Details</a>
                    </p-link-pure>
                  </div>
                </ng-container>
              </div>

              <div class="change">
                <p-text [color]="'inherit'" [weight]="'semi-bold'">
                  <ng-container
                    *ngIf="
                      log.type === TransactionLogType.REMAINING ||
                      log.type === TransactionLogType.AVAILABLE
                    "
                  >
                    {{ log.budgetAfter | currency: 'EUR' }}
                  </ng-container>

                  <ng-container
                    *ngIf="
                      log.type === TransactionLogType.ALLOWANCE ||
                      log.type === TransactionLogType.COST ||
                      log.type === TransactionLogType.CORRECTION
                    "
                  >
                    {{ log.budgetChange | currency: 'EUR' | leadingPlus }}
                  </ng-container>
                </p-text>
              </div>
            </div>
            <p-divider aria-hidden="true" />
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
