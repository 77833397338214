import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  type OnChanges,
  type OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, type FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, type Observable } from 'rxjs';
import { SubSink } from 'subsink';
import type {
  CreateOrEditInventoryItemService,
  InventoryItemServiceEntry,
} from '../../shared/models/api/inventory-item-service.model';
import type { InventoryItem } from '../../shared/models/api/inventory-item.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import type { NgChanges } from '../../shared/models/ng-changes.model';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type {
  CreateInventoryItemServiceData,
  InventoryItemServiceFormValue,
} from './inventory-item-service-create.models';

@Component({
  selector: 'opa-inventory-item-service-form',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './inventory-item-service-form.component.html',
  styleUrl: './inventory-item-service-form.component.scss',
})
export class InventoryItemServiceFormComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryService = inject(InventoryItemsService);
  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.asObservable();

  @Input() inventoryItemServiceEntry: InventoryItemServiceEntry | null = null;
  @Output() readonly submitForm = new EventEmitter<CreateOrEditInventoryItemService>();

  readonly textareaMaxLength = 255;
  formData$ = this.getCreateInventoryItemServiceData$();
  form = this.buildForm();

  ngOnChanges(changes: NgChanges<InventoryItemServiceFormComponent>): void {
    if (changes.inventoryItemServiceEntry) {
      this.form = this.buildForm();
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadFormData(): void {
    this.formData$ = this.getCreateInventoryItemServiceData$();
  }

  trackInventoryItem(index: number, inventoryItem: InventoryItem): number {
    return inventoryItem.id;
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const item = this.formValueToInventoryItemService(formValue);

    this.submitForm.emit(item);
  }

  finalizeSubmit(): void {
    this.submitting.next(false);
  }

  private buildForm(): FormGroup<ControlsOf<InventoryItemServiceFormValue>> {
    const inventoryService = this.inventoryItemServiceEntry;

    const form = this.formBuilder.nonNullable.group<ControlsOf<InventoryItemServiceFormValue>>({
      serviceDocument: this.formBuilder.control(null),
      inventoryItemId: this.formBuilder.control(
        inventoryService?.inventoryItem?.id || null,
        OpaValidators.required(),
      ),
      description: this.formBuilder.control(
        inventoryService?.description ?? null,
        OpaValidators.required(),
      ),
      sentToServiceDate: this.formBuilder.control(
        inventoryService?.sentToServiceDate ?? null,
        OpaValidators.required(),
      ),
      returnedFromServiceDate: this.formBuilder.control(
        inventoryService?.returnedFromServiceDate ?? null,
      ),
    });
    return form;
  }

  onServiceDocumentChange(event: Event): void {
    if (!this.form) return;
    if (!(event.target as HTMLInputElement).files?.length) return;

    this.form.patchValue({
      serviceDocument: (event.target as HTMLInputElement).files![0],
    });
  }

  private formValueToInventoryItemService(
    form: InventoryItemServiceFormValue,
  ): CreateOrEditInventoryItemService {
    const inventoryItemService: CreateOrEditInventoryItemService = {
      serviceDocument: form.serviceDocument,
      command: {
        inventoryItemId: Number(form.inventoryItemId!),
        description: form.description!,
        sentToServiceDate: form.sentToServiceDate!,
        returnedFromServiceDate: form.returnedFromServiceDate,
      },
    };
    return inventoryItemService;
  }

  private getCreateInventoryItemServiceData$(): Observable<CreateInventoryItemServiceData> {
    // TODO: add probe to track vendors refreshing and add loader in template
    const inventoryItems$ = this.inventoryService.getInventoryItems(null);
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([inventoryItems$, submitting$]).pipe(
      map(([inventoryItems, submitting]) => ({ inventoryItems, submitting })),
    );
  }
}
