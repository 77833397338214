import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, type FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  type Observable,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { TableFilterComponent } from '../../shared/components/table-filter/table-filter.component';
import { TableSortComponent } from '../../shared/components/table-sort/table-sort.component';
import type {
  TableSort,
  TableSortDirection,
} from '../../shared/components/table-sort/table-sort.models';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type {
  InventoryItemServiceEntry,
  InventoryItemServiceEntryFilter,
} from '../../shared/models/api/inventory-item-service.model';
import type { Page } from '../../shared/models/api/page.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { InventoryItemServiceService } from '../../shared/services/api/inventory-item-service.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import type { InventoryItemServiceListFormValue } from './inventory-item-service-list.model';

@Component({
  selector: 'opa-inventory-item-service-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    FillArrayPipe,
    ReactiveFormsModule,
    TableFilterComponent,
    TableSortComponent,
  ],
  templateUrl: './inventory-item-service-list.component.html',
  styleUrl: './inventory-item-service-list.component.scss',
})
export class InventoryItemServiceListComponent {
  private readonly inventoryItemServiceService = inject(InventoryItemServiceService);
  private readonly formBuilder = inject(FormBuilder);

  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();

  private readonly pageNo = new BehaviorSubject(1);
  readonly allValue = 'all' as const;
  readonly form = this.buildForm();
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  private readonly sort = new BehaviorSubject<TableSort | undefined>(undefined);
  readonly sort$ = this.sort.pipe(distinctUntilChanged((a, b) => objectsEqual(a, b)));
  inventoryItemServices$ = this.getInventoryItemServices$();

  reloadInventoryItemServices(): void {
    this.inventoryItemServices$ = this.getInventoryItemServices$();
  }

  resetFilters(): void {
    this.sort.next(undefined);
    this.form.reset();
  }

  updateSort(sortId: string, direction: TableSortDirection | undefined): void {
    if (!direction) this.sort.next(undefined);
    else this.sort.next({ sortId, direction });
  }

  trackRow(index: number): number {
    return index;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<InventoryItemServiceListFormValue>> {
    // TODO: make all form builders nonNullable in the project
    const form = this.formBuilder.nonNullable.group<ControlsOf<InventoryItemServiceListFormValue>>({
      inventoryItemNameOrSerialNumber: this.formBuilder.nonNullable.control(null),
      sentToServiceDate: this.formBuilder.nonNullable.control(null),
      returnedFromServiceDate: this.formBuilder.nonNullable.control(null),
    });
    return form;
  }

  private getInventoryItemServices$(): Observable<Page<InventoryItemServiceEntry>> {
    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());
    const form$ = merge(
      ...Object.entries(this.form.controls).map(([key, control]) => {
        const controlName = key as keyof InventoryItemServiceListFormValue;

        let value$ = control.valueChanges as Observable<unknown>;
        value$ = value$.pipe(
          startWith(control.value),
          distinctUntilChanged(),
          skip(1),
          map(() => undefined),
        );
        if (controlName === 'inventoryItemNameOrSerialNumber')
          value$ = value$.pipe(debounceTime(500));

        return value$;
      }),
    ).pipe(
      startWith(undefined),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    const sort$ = this.sort$.pipe(tap(() => this.updatePageNo(1)));

    return combineLatest([pageNo$, form$, sort$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form, sort]) => {
        const filter: InventoryItemServiceEntryFilter = {
          inventoryItemNameOrSerialNumber: form.inventoryItemNameOrSerialNumber ?? undefined,
          sentToServiceDate: form.sentToServiceDate ?? undefined,
          returnedFromServiceDate: form.returnedFromServiceDate ?? undefined,
        };

        return this.inventoryItemServiceService.getInventoryItemServices(pageNo, filter, sort);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
