import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  distinctUntilChanged,
  EMPTY,
  merge,
  type Observable,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SubSink } from 'subsink';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type {
  CreateOrEditInventoryItemService,
  InventoryItemServiceEntry,
} from '../../shared/models/api/inventory-item-service.model';
import { InventoryItemServiceService } from '../../shared/services/api/inventory-item-service.service';
import { InventoryItemServiceFormComponent } from '../inventory-item-service-form/inventory-item-service-form.component';

@Component({
  selector: 'opa-inventory-item-service-edit',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    LetDirective,
    RouterLink,
    InventoryItemServiceFormComponent,
  ],
  templateUrl: './inventory-item-service-edit.component.html',
  styleUrl: './inventory-item-service-edit.component.scss',
})
export class InventoryItemServiceEditComponent implements OnDestroy {
  @ViewChild(InventoryItemServiceFormComponent) form?: InventoryItemServiceFormComponent;

  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly inventoryItemServiceService = inject(InventoryItemServiceService);

  private readonly subSink = new SubSink();
  inventoryItemService$ = this.getInventoryItemService();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onSubmit(data: CreateOrEditInventoryItemService): void {
    this.subSink.sink = this.inventoryItemService$
      .pipe(
        switchMap((item) =>
          this.inventoryItemServiceService.edit(item.id, data).pipe(
            tap({
              next: (editItem) => {
                this.toastManager.addMessage({
                  text: `Inventory item service ${editItem.id} updated successfully.`,
                  state: 'success',
                });
                this.router.navigate(['/inventory-item-services', editItem.id]);
              },
              error: () => this.form?.finalizeSubmit(),
            }),
          ),
        ),
      )
      .subscribe();
  }

  private getInventoryItemService(): Observable<InventoryItemServiceEntry> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadInventoryItemService()),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const id = Number(routeParams['id']);
        return this.inventoryItemServiceService.getInventoryItemService(id);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private reloadInventoryItemService(): void {
    this.inventoryItemService$ = this.getInventoryItemService();
  }
}
