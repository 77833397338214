<form *ngIf="form" class="form" [formGroup]="form" (ngSubmit)="submit()">
  <ng-container *ngrxLet="formData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadFormData()"
      >
        Reload Form Data
      </p-button-pure>
    </div>

    <p-select-wrapper
      *ngrxLet="form.controls.manufacturerId as control"
      [filter]="true"
      [label]="'Manufacturer'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option
          *ngFor="let manufacturer of data.manufacturers; trackBy: trackManufacturer"
          [value]="manufacturer.id"
        >
          {{ manufacturer.description }}
        </option>
      </select>
    </p-select-wrapper>

    <div>
      <p-button-pure
        [iconSource]="'assets/icons/add.svg'"
        [type]="'button'"
        [underline]="true"
        (click)="showCreateManufacturerModal = true"
      >
        Add New Inventory Manufacturer
      </p-button-pure>
    </div>

    <p-text-field-wrapper
      *ngrxLet="form.controls.model as control"
      [label]="'Model'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="text" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.specification as control"
      [label]="'Specification'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="text" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-select-wrapper
      *ngrxLet="form.controls.typeId as control"
      [filter]="true"
      [label]="'Type'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option *ngFor="let type of data.types; trackBy: trackType" [value]="type.id">
          {{ type.description }}
        </option>
      </select>
    </p-select-wrapper>

    <div>
      <p-button-pure
        [iconSource]="'assets/icons/add.svg'"
        [type]="'button'"
        [underline]="true"
        (click)="showCreateTypeModal = true"
      >
        Add New Inventory Type
      </p-button-pure>
    </div>

    <p-text-field-wrapper
      *ngrxLet="form.controls.serialNumber as control"
      [label]="'Serial Number (S/N)'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="text" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.imei as control"
      [label]="'IMEI'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input
        type="text"
        [attr.required]="(isImeiRequired$ | async) === true ? true : null"
        [formControl]="control"
      />
    </p-text-field-wrapper>

    <p-select-wrapper
      *ngrxLet="form.controls.ownerId as control"
      [filter]="true"
      [label]="'Owner'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option *ngFor="let owner of data.owners; trackBy: trackOwner" [value]="owner.id">
          {{ owner.description }}
        </option>
      </select>
    </p-select-wrapper>

    <div>
      <p-button-pure
        [iconSource]="'assets/icons/add.svg'"
        [type]="'button'"
        [underline]="true"
        (click)="showCreateOwnerModal = true"
      >
        Add New Inventory Owner
      </p-button-pure>
    </div>

    <p-select-wrapper
      *ngrxLet="form.controls.locationId as control"
      [filter]="true"
      [label]="'Location'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option *ngFor="let location of data.locations; trackBy: trackOwner" [value]="location.id">
          {{ location.description }}
        </option>
      </select>
    </p-select-wrapper>

    <div>
      <p-button-pure
        [iconSource]="'assets/icons/add.svg'"
        [type]="'button'"
        [underline]="true"
        (click)="showCreateLocationModal = true"
      >
        Add New Inventory Location
      </p-button-pure>
    </div>

    <p-text-field-wrapper
      *ngrxLet="form.controls.warrantyExpiration as control"
      [label]="'Warranty Expiration'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="date" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.contractNumber as control"
      [label]="'Contract No.'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="text" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.price as control"
      [label]="'Price'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
      [unit]="'EUR'"
      [unitPosition]="'suffix'"
    >
      <input
        type="number"
        [placeholder]="'0,00'"
        [attr.required]="true"
        [formControl]="control"
        [min]="minAmount"
      />
    </p-text-field-wrapper>

    <p-select-wrapper
      *ngrxLet="form.controls.assigneeId as control"
      [filter]="true"
      [label]="'Assignee'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [formControl]="control">
        <option [value]="null">None</option>

        <option *ngFor="let user of data.users; trackBy: trackUser" [value]="user.id">
          {{ user.firstName }} {{ user.lastName }}
        </option>
      </select>
    </p-select-wrapper>

    <p-textarea-wrapper
      *ngrxLet="form.controls.note as control"
      [label]="'Note (Optional)'"
      [message]="control.errors?.['message'] ?? ''"
      [showCounter]="true"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <textarea
        [formControl]="control"
        [maxlength]="textareaMaxLength"
        [placeholder]="'Type here...'"
      ></textarea>
    </p-textarea-wrapper>

    <p-select-wrapper
      *ngrxLet="form.controls.statusId as control"
      [filter]="true"
      [label]="'Status'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option *ngFor="let status of data.statuses; trackBy: trackStatus" [value]="status.id">
          {{ status.description }}
        </option>
      </select>
    </p-select-wrapper>

    <div>
      <p-button-pure
        [iconSource]="'assets/icons/add.svg'"
        [type]="'button'"
        [underline]="true"
        (click)="showCreateStatusModal = true"
      >
        Add New Inventory Status
      </p-button-pure>
    </div>
  </ng-container>

  <section class="file-input" [ngClass]="{ hidden: (formData$ | async) === null }">
    <p-text>Receipt document (Optional) </p-text>
    <input type="file" (change)="onReceiptChange($event)" />
  </section>

  <section class="file-input" [ngClass]="{ hidden: (formData$ | async) === null }">
    <p-text>Warranty document (Optional) </p-text>
    <input type="file" (change)="onWarrantyChange($event)" />
  </section>

  <p-inline-notification *ngIf="form.errors" [state]="'error'">
    <p-text>{{ form.errors['message'] }}</p-text>
  </p-inline-notification>

  <div class="actions" [ngClass]="{ hidden: (formData$ | async) === null }">
    <p-button
      [icon]="'none'"
      [loading]="(submitting$ | async) ?? false"
      [type]="'submit'"
      [variant]="'primary'"
    >
      {{ inventoryItem?.id ? 'Edit' : 'Create' }} Item
    </p-button>
  </div>
</form>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<opa-inventory-status-create
  [open]="showCreateStatusModal"
  (dismiss)="showCreateStatusModal = false; $event && reloadFormData()"
/>

<opa-inventory-type-create
  [open]="showCreateTypeModal"
  (dismiss)="showCreateTypeModal = false; $event && reloadFormData()"
/>

<opa-inventory-owner-create
  [open]="showCreateOwnerModal"
  (dismiss)="showCreateOwnerModal = false; $event && reloadFormData()"
/>

<opa-inventory-location-create
  [open]="showCreateLocationModal"
  (dismiss)="showCreateLocationModal = false; $event && reloadFormData()"
/>

<opa-inventory-manufacturer-create
  [open]="showCreateManufacturerModal"
  (dismiss)="showCreateManufacturerModal = false; $event && reloadFormData()"
/>
