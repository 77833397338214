import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { TableSort } from '../../components/table-sort/table-sort.models';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateOrEditInventoryItemService,
  InventoryItemServiceEntry,
  InventoryItemServiceEntryFilter,
} from '../../models/api/inventory-item-service.model';
import type { Page } from '../../models/api/page.model';
import { DownloadService } from '../download.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryItemServiceService {
  private readonly httpClient = inject(HttpClient);
  private readonly downloadService = inject(DownloadService);

  private readonly api = `${environment.apiUrl}/v1/inventory-item-services`;

  getInventoryItemServices(
    pageNo: number | null,
    filter?: InventoryItemServiceEntryFilter,
    sort?: TableSort,
  ): Observable<Page<InventoryItemServiceEntry>> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Inventory Item' + ' Services',
    );

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (filter) {
      Object.entries(filter)
        .filter(([, value]) => value !== undefined)
        .forEach(([key, value]) => (params = params.set(key, value)));
    }
    if (sort) params = params.set('sort', `${sort.sortId},${sort.direction}`);

    return this.httpClient.get<Page<InventoryItemServiceEntry>>(this.api, { context, params });
  }

  createInventoryItemService(
    inventoryItemService: CreateOrEditInventoryItemService,
  ): Observable<InventoryItemServiceEntry> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Inventory Item' + ' Service',
    );

    const formData = new FormData();
    if (inventoryItemService.serviceDocument)
      formData.append('file', inventoryItemService.serviceDocument);

    formData.append(
      'command',
      new Blob([JSON.stringify(inventoryItemService.command)], {
        type: 'application/json',
      }),
    );

    return this.httpClient.post<InventoryItemServiceEntry>(this.api, formData, { context });
  }

  getInventoryItemService(inventoryItemServiceId: number): Observable<InventoryItemServiceEntry> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Inventory Item' + ' Service',
    );
    return this.httpClient.get<InventoryItemServiceEntry>(`${this.api}/${inventoryItemServiceId}`, {
      context,
    });
  }

  edit(
    id: number,
    inventoryItemService: CreateOrEditInventoryItemService,
  ): Observable<InventoryItemServiceEntry> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Update Inventory Item' + ' Service',
    );

    const formData = new FormData();
    if (inventoryItemService.serviceDocument)
      formData.append('file', inventoryItemService.serviceDocument);

    if (inventoryItemService.command) {
      formData.append(
        'command',
        new Blob([JSON.stringify(inventoryItemService.command)], {
          type: 'application/json',
        }),
      );
    }

    return this.httpClient.put<InventoryItemServiceEntry>(`${this.api}/${id}`, formData, {
      context,
    });
  }

  downloadServiceDocument(id: number): Observable<void> {
    return this.downloadService.downloadFile(
      `${this.api}/${id}/service-document`,
      'Failed to' + ' Get Service Document',
    );
  }
}
