import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateOrEditInventoryItemService } from '../../shared/models/api/inventory-item-service.model';
import { InventoryItemServiceService } from '../../shared/services/api/inventory-item-service.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryItemServiceFormComponent } from '../inventory-item-service-form/inventory-item-service-form.component';

@Component({
  selector: 'opa-inventory-item-service-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    InventoryItemServiceFormComponent,
  ],
  templateUrl: './inventory-item-service-create.component.html',
  styleUrl: './inventory-item-service-create.component.scss',
})
export class InventoryItemServiceCreateComponent implements OnDestroy {
  @ViewChild(InventoryItemServiceFormComponent) form?: InventoryItemServiceFormComponent;

  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  private readonly inventoryItemServiceService = inject(InventoryItemServiceService);

  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onSubmit(item: CreateOrEditInventoryItemService): void {
    this.subSink.sink = this.inventoryItemServiceService
      .createInventoryItemService(item)
      .pipe(
        tap({
          next: (createItemService) => {
            this.toastManager.addMessage({
              text: `Inventory item service ${createItemService.id} created`,
              state: 'success',
            });
            this.router.navigate(['/inventory-item-services']);
          },
          error: () => this.form?.finalizeSubmit(),
        }),
      )
      .subscribe();
  }
}
